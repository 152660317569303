import gql from 'graphql-tag';

export const UPDATE_PHASE_SETTINGS = gql`
  mutation updatePhaseSettings($id: ID!, $input: PhaseSettingsInput) {
    phaseSettings(id: $id, input: $input) {
      id
      canReceiveCardDirectlyFromDraft
      color
      description
      done
      latenessTime
      name
      identifyTask
      teamMembers {
        id
        name
        avatarUrl
      }
    }
  }
`;
