import gql from 'graphql-tag';

export const GET_PHASE_SETTINGS = gql`
  query getPhaseSettings($id: ID!) {
    phaseSettings(id: $id) {
      id
      canReceiveCardDirectlyFromDraft
      color
      description
      done
      latenessTime
      name
      identifyTask
      teamMembers {
        id
        name
        avatarUrl
      }
    }
  }
`;
