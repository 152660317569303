import React from 'react';

import { LumenProvider } from '@pipefyteam/lumen.base';

import { useFeatureFlag } from 'Commons/hooks/useFeatureFlag';

export const WithLumenProvider: React.FC<{ language: string }> = ({
  children,
  language,
}) => {
  const { isEnabled: isLumenFieldsEnabled } = useFeatureFlag({
    feature: 'use_lumen_fields',
  });

  return (
    <LumenProvider
      language={language}
      lumenFeatureToggleList={{ fields: isLumenFieldsEnabled }}
    >
      {children}
    </LumenProvider>
  );
};
