import { GET_ACTIVE_SIDEBAR } from 'Commons/graphql';
import GET_COLUMNS_TO_SHOW from 'Database/graphql/queries/getColumnsToShow';

export const defaults = {};

export const resolvers = {
  Query: {
    fields: {
      columnsToShow: (columns, _args, { cache }) => {
        const { columnsToShow } = cache.readQuery({
          query: GET_COLUMNS_TO_SHOW,
        });
        return columnsToShow;
      },
      activeSidebar: (prevActiveSidebar, _args, { cache }) => {
        const { activeSidebar } = cache.readQuery({
          query: GET_ACTIVE_SIDEBAR,
        });
        return activeSidebar;
      },
    },
  },
};

export const initialData = {
  data: {
    columnsToShow: [],
    activeSidebar: '',
  },
};
