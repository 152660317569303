import moment from 'moment';
import momentTZ from 'moment-timezone';

import { DEFAULT_LOCALE, LOCALES } from './constants';

export const convertLocale = (locale: string): string => {
  return LOCALES[locale] || LOCALES['en-US'];
};

export const getBrowserLocale = (): string => {
  let locale =
    (navigator.languages && navigator.languages[0]) ||
    navigator.language ||
    'en-US';

  if (locale === 'pt') {
    locale = 'pt-BR';
  } else if (locale === 'en') {
    locale = 'en-US';
  }

  return Object.keys(LOCALES).indexOf(locale) === -1 ? DEFAULT_LOCALE : locale;
};

const requireLocale = (locale: string): void => {
  switch (locale) {
    case 'pt-br':
      require('moment/locale/pt-br');
      break;

    case 'es':
      require('moment/locale/es');
      break;

    case 'en-gb':
      require('moment/locale/en-gb');
      break;

    default:
  }
};

// this workaround is necessary due to an open issue at the moment-timezone repository (https://github.com/moment/moment-timezone/issues/647)
export const setLocale = (name: string = getBrowserLocale()): void => {
  const locale = convertLocale(name);
  requireLocale(locale);

  moment.locale(locale);
  //@ts-expect-error
  momentTZ.updateLocale(locale, moment.localeData()._config);
  momentTZ.locale(locale);
};

export const setTimezone = (timezone: string = momentTZ.tz.guess()): void => {
  momentTZ.tz.setDefault(timezone);
};
