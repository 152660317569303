import './polyfills';
import './assets/public-form.scss';

import React from 'react';

import logger, { loadGlobalAPM } from '@pipefyteam/utils.logger';

import { loadI18n } from 'I18n';
import ReactDOM from 'react-dom';
import { WithLumenProvider } from 'utils/WithLumenProvider';
import { getBrowserLocale } from 'utils/moment';

import Loader from './Loader';

const isProduction = process.env.NODE_ENV === 'production';

/**
 * This is a hack to overwrites the PUBLIC_URL from create-react-app.
 */
if (process.env.REACT_APP_PUBLIC_FORM_URL) {
  __webpack_public_path__ = process.env.REACT_APP_PUBLIC_FORM_URL;
}

logger.configure({
  debug: { console: !isProduction },
});

loadGlobalAPM()
  .then((apm) => {
    if (apm) {
      logger.setApmAgent(apm);
    }
  })
  .catch((err) => {
    console.error(err);
  });

const PublicForm = Loader({
  component: () =>
    import(/* webpackChunkName: "publicForm" */ './PublicForm/PublicView'),
});

/* Empty object to keep legacy schema */
type Props = Record<string, never>;

window.PipeUI = {
  initPublicForm: async (props: Props, el: Element): Promise<void> => {
    await loadI18n();
    ReactDOM.render(
      <WithLumenProvider language={getBrowserLocale()}>
        <PublicForm />
      </WithLumenProvider>,
      el
    );
  },
};
