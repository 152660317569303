import { merge } from 'lodash';

const lazyLoadedLocales = {
  'pt-BR': (): Promise<unknown> =>
    import(/* webpackChunkName: "pt-BR-translations" */ './locales/pt-BR'),
  en: (): Promise<unknown> =>
    import(/* webpackChunkName: "en-US-translations" */ './locales/en-US'),
  'en-US': (): Promise<unknown> =>
    import(/* webpackChunkName: "en-US-translations" */ './locales/en-US'),
  es: (): Promise<unknown> =>
    import(/* webpackChunkName: "es-translations" */ './locales/es'),
};

export const loadI18n = async (): Promise<void> => {
  if (!window.I18n) return;

  const locale = window.I18n.locale;

  const keys = await lazyLoadedLocales[locale]();

  window.I18n.translations = merge(window.I18n.translations, {
    [locale]: keys.default,
  });
};
