import gql from 'graphql-tag';

export default gql`
  query RepoMembersQuery(
    $repoId: ID
    $cardId: ID
    $membersEndCursor: String
    $filter: RepoMemberFilterParams
  ) {
    repoMembers(
      repoId: $repoId
      cardId: $cardId
      after: $membersEndCursor
      filter: $filter
    ) {
      edges {
        node {
          avatarUrl
          email
          id
          name
          username
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;
