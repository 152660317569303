import * as AbsintheSocket from '@absinthe/socket';
import { createAbsintheSocketLink } from '@absinthe/socket-apollo-link';
import { hasSubscription } from '@jumpn/utils-graphql';
import { ApolloLink, split } from 'apollo-link';
import { createHttpLink } from 'apollo-link-http';

import pusher from 'legacy/utils/pusher';

import { activitiesSocket } from './activitiesSocket';

const ACTIVITIES_URI = window.ENV?.PFY_PIPEFY_ACTIVITIES_HTTP_URI || '';

const AuthLink = new ApolloLink((operation, forward) => {
  const csrfMeta = document?.head?.querySelector('[name="csrf-token"]');

  operation.setContext(({ headers }) => ({
    headers: {
      ...headers,
      'Content-Type': 'application/json',
      ...(csrfMeta && { 'x-CSRF-token': csrfMeta.content }),
    },
    credentials: 'same-origin',
  }));

  return forward(operation);
});

const PusherLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers }) => ({
    headers: {
      ...headers,
      ...(pusher?.connection?.socket_id && {
        'X-Pusher-Socket-ID': pusher.connection.socket_id,
      }),
    },
  }));

  return forward(operation);
});

const interfacesApiLink = createHttpLink({
  uri: `${process.env.REACT_APP_PIPEFY_URL}/graphql/interfaces`,
});

const internalApiLink = createHttpLink({
  uri: `${process.env.REACT_APP_PIPEFY_URL}/internal_api`,
});

const publicApiLink = createHttpLink({
  uri: `${process.env.REACT_APP_PIPEFY_URL}/queries`,
});

const coreApiLink = createHttpLink({
  uri: `${process.env.REACT_APP_PIPEFY_URL}/graphql/core`,
});

const legacyPublicApiLink = createHttpLink({
  uri: `${process.env.REACT_APP_PIPEFY_URL}/public_api`,
});

const requestTrackerApiLink = createHttpLink({
  uri: `${process.env.REACT_APP_PIPEFY_URL}/request-tracker/graphql`,
});

const activitiesApiLink = createHttpLink({
  uri: ACTIVITIES_URI,
  credentials: 'include',
});

const activitiesSocketLink = createAbsintheSocketLink(
  AbsintheSocket.create(activitiesSocket)
);

const isActivitiesOperation = (operation) => {
  return (
    operation.getContext().activities ||
    operation.variables?.context?.activities === true
  );
};

const linkActivities = () =>
  split(
    (operation) => hasSubscription(operation.query),
    activitiesSocketLink,
    activitiesApiLink
  );

export default split(
  isActivitiesOperation,
  linkActivities(),
  AuthLink.concat(
    split(
      (operation) => operation.getContext().legacy,
      legacyPublicApiLink,
      PusherLink.concat(
        split(
          (operation) => operation.getContext().core,
          coreApiLink,
          split(
            (operation) => operation.getContext().interfaces,
            interfacesApiLink,
            split(
              (operation) => operation.getContext().requestTracker,
              requestTrackerApiLink,
              split(
                (operation) => operation.getContext().public,
                publicApiLink,
                internalApiLink
              )
            )
          )
        )
      )
    )
  )
);
