import { ID } from '@pipefyteam/types.base-types';

import gql from 'graphql-tag';

export type RepoQueryVariables = {
  id: ID;
};

export type RepoQueryResult = {
  repo: {
    id: number;
    uuid: string;
    name: string;
    icon: string;
    color: string;
  };
};

export default gql`
  query Repo($id: ID!) {
    repo: pipe(id: $id) {
      id
      uuid
      name
      color
      icon
    }
  }
`;
