import React from 'react';

import { Spinner } from '@pipefyteam/react-pipestyle';

import Loadable from 'react-loadable';
import { Portal } from 'react-portal';

export const Loading = (): React.ReactElement => (
  <div
    data-testid="loader"
    id="loader"
    className="pp-always-center pp-height-100 pp-width-100"
  >
    <Spinner size="xl" className="pp-color-blue-base" />
  </div>
);

const LoadingWithPortal = (): React.ReactElement => (
  <Portal>
    <div
      data-testid="loader"
      id="loader"
      className="pp-always-center pp-height-100 pp-width-100 pp-position-absolute pp-top"
    >
      <Spinner size="xl" className="pp-color-blue-base" />
    </div>
  </Portal>
);

export const EmptyLoading = (): null => null;

type LoaderProps = {
  component: () => Promise<
    | React.ComponentType<Record<string, unknown>>
    | { default: React.ComponentType<Record<string, unknown>> }
  >;
  showLoading?: boolean;
  usePortal?: boolean;
};

const Loader = ({
  component,
  showLoading = true,
  usePortal = true,
}: LoaderProps): React.ComponentType<Record<string, unknown>> &
  Loadable.LoadableComponent =>
  Loadable({
    loader: component,
    loading: showLoading
      ? usePortal
        ? LoadingWithPortal
        : Loading
      : EmptyLoading,
  });

export default Loader;
