// Locales
export const DEFAULT_LOCALE = 'en-US';
export const RUSSIAN_LOCALE = 'ru';

export const LOCALES = {
  en: 'en-gb',
  'en-gb': 'en-gb',
  'en-GB': 'en-gb',
  'pt-br': 'pt-br',
  'pt-BR': 'pt-br',
  'en-us': 'en',
  'en-US': 'en',
  ru: RUSSIAN_LOCALE,
  es: 'es',
  fr: 'fr',
};

// Timezones
export const DEFAULT_TIMEZONE = 'Etc/UTC';

// Timeouts
export const SHORT_TIMEOUT_INTERVAL = 300;
export const MEDIUM_TIMEOUT_INTERVAL = 1000;
export const LONG_TIMEOUT_INTERVAL = 3000;
export const DELAY_TO_CONFIRM_FIELD = MEDIUM_TIMEOUT_INTERVAL;
export const ERROR_TIMEOUT_INTERVAL = 5000;
export const TRIGGER_PIPEFILTER_TIMEOUT = 500;
export const TRIGGER_FIELDFILTER_TIMEOUT = 500;

// KeyCodes
export const TAB_KEYCODE = 9;
export const BACKSPACE_KEYCODE = 8;
export const ENTER_KEYCODE = 13;
export const ESCAPE_KEYCODE = 27;
export const SPACE_KEYCODE = 32;
export const ARROW_LEFT_KEYCODE = 37;
export const ARROW_RIGHT_KEYCODE = 39;
export const ARROW_UP_KEYCODE = 38;
export const ARROW_DOWN_KEYCODE = 40;
export const DELETE_KEYCODE = 46;
export const AT_SYMBOL_KEYCODE = 50;
export const E_KEYCODE = 69;
export const CTRL_KEYCODE = 17;
export const LETTER_A_KEYCODE = 65;
export const LETTER_C_KEYCODE = 67;
export const ADD_KEYCODE = 187;
export const NUMPAD_ADD_KEYCODE = 107;

// Keys
export const ARROW_DOWN_KEY = 'ArrowDown';
export const ARROW_LEFT_KEY = 'ArrowLeft';
export const ARROW_RIGHT_KEY = 'ArrowRight';
export const ARROW_UP_KEY = 'ArrowUp';
export const AT_SYMBOL_KEY = '@';
export const BACKSPACE_KEY = 'Backspace';
export const DELETE_KEY = 'Delete';
export const ENTER_KEY = 'Enter';
export const ESCAPE_KEY = 'Escape';
export const TAB_KEY = 'Tab';

// Files
export const MAX_FILE_SIZE_MB = 512; // 512 MB
export const MAX_FILE_SIZE = 1024 * 1024 * MAX_FILE_SIZE_MB;

// Documents
export const CPF_DOCUMENT_TYPE = 'cpf';
export const CNPJ_DOCUMENT_TYPE = 'cnpj';

export const RUSSIAN_DATE_FORMAT = 'DD.MM.YYYY';
export const EN_US_DATE_FORMAT = 'MM/DD/YYYY';
export const STANDARD_DATE_FORMAT = 'DD/MM/YYYY'; // STANDARD because it is used in all others locales different than en-us
export const ONE_DAY_TIME = 24 * 60 * 60 * 1000;

// Button names
export const EMAIL_TEMPLATES_BUTTON_NAME = 'EmailTemplate';

// window.history states
export const TEMPLATE_GALLERY_MODAL_OPENED = 'template-gallery-modal-opened';

// Icons
export const CONNECTION_ICON = `<svg width="14" height="14" style="position: relative;top: 2px" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.2474 6.21074C7.3054 6.15141 7.3334 6.07541 7.3334 5.99941C7.3334 5.92074 7.30407 5.84608 7.2474 5.78808L5.58407 4.08741C5.46673 3.96808 5.2834 3.97274 5.1674 4.09074L4.75607 4.51141C4.6354 4.63474 4.63873 4.82008 4.7534 4.93741L5.79273 6.00008L4.7534 7.06208C4.63607 7.18208 4.64007 7.36941 4.75607 7.48741L5.1674 7.90874C5.28873 8.03141 5.4694 8.02808 5.58407 7.91141L7.2474 6.21074ZM10 2.00434C10 2.00434 9.998 9.99967 9.99533 9.99967C9.99533 9.99967 2 9.99767 2 9.99501C2 9.99501 2.00133 1.99967 2.00467 1.99967C2.00467 1.99967 10 2.00167 10 2.00434ZM0.666679 2.00434L0.666679 9.99501C0.666679 10.735 1.26535 11.333 2.00468 11.333H9.99535C10.7347 11.333 11.3333 10.7343 11.3333 9.99501V2.00434C11.3333 1.26501 10.7347 0.666342 9.99535 0.666342H2.00468C1.26535 0.666342 0.666679 1.26567 0.666679 2.00434Z" fill="white"/></svg>`;
export const TYPE_FIELD_ICONS = {
  short_text: 'i-type',
  long_text: 'i-long-text',
  statement: 'i-statement',
  attachment: 'i-attachments',
  checklist_vertical: 'i-checklist',
  checklist_horizontal: 'i-checklist',
  assignee_select: 'i-person',
  date: 'i-calendar-1',
  datetime: 'i-calendar-2',
  due_date: 'i-calendar-1',
  label_select: 'i-label',
  email: 'i-email-at',
  phone: 'i-phone',
  select: 'i-drop-down',
  radio_horizontal: 'i-radio-button',
  radio_vertical: 'i-radio-button',
  time: 'i-clock',
  currency: 'i-currency',
  cpf: 'i-document-id',
  cnpj: 'i-document-id',
  id: 'i-card-id',
  connector: 'i-connection',
  number: 'i-numeric',
};

// Upload
export const UPLOAD_CONTENT_DISPOSITION = 'attachment';
export const UPLOAD_SERVER_URL = '/';
export const UPLOAD_SIGNING_URL = 'upload_attachments/new';
export const UPLOAD_S3_PATH = '';
export const UPLOAD_HEADERS = { 'x-ms-blob-type': 'BlockBlob' };

// Errors
export const UNPROCESSABLE_ENTITY = 422;

export enum GraphqlPageInfo {
  Typename = 'PageInfo',
}
