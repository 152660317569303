import gql from 'graphql-tag';
import { UnleashFeature } from 'types/FeatureFlag';

export type GetFeatureFlagSuccess = {
  featureFlags: Omit<UnleashFeature, 'variant'>[];
};

export const GET_FEATURE_FLAGS = gql`
  query GetFeatureFlags {
    featureFlags {
      isEnabled
      name
    }
  }
`;
