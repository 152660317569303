export const getEnv = (): string | undefined => {
  if (typeof process !== 'undefined' && process.env.NODE_ENV) {
    return process.env.NODE_ENV;
  }

  // default environment
  return 'development';
};

export const isEnv = (env: string): boolean => {
  return getEnv() === env;
};

export const isProduction = (): boolean => {
  return isEnv('production') === true;
};

export const isDevelopment = (): boolean => {
  return isEnv('development') === true;
};

export const isTest = (): boolean => {
  return isEnv('test') === true;
};
