import { User } from '@pipefyteam/types.base-types';

import gql from 'graphql-tag';

export type MeQueryResult = {
  me: Pick<
    User,
    | 'avatarUrl'
    | 'created_at'
    | 'departmentKey'
    | 'email'
    | 'id'
    | 'locale'
    | 'name'
    | 'username'
    | 'uuid'
    | 'signupData'
  > & {
    timezone: User['timeZone'];
    invited: boolean;
    currentOrganizationRole: string;
  };
};

export default gql`
  query Me {
    me {
      avatarUrl
      created_at
      departmentKey
      email
      id
      locale
      name
      username
      timezone
      uuid
      signupData
      invited
      currentOrganizationRole
    }
  }
`;
