import Pusher from 'pusher-js';

/**
  You need to put this to your test file to mock pusher-js
  jest.mock("pusher-js", () => {
    //eslint-disable-next-line
    const Pusher = require("pusher-js-mock").PusherMock;
    return Pusher;
  });
*/

Pusher.logToConsole = true;

const pusherKey = (window.ENV && window.ENV.PFY_PUSHER_KEY) || '';

if (!pusherKey) {
  console.warn('No pusher key detected on window.ENV.PFY_PUSHER_KEY');
}

const pusher = new Pusher(pusherKey);

window.Pusher = pusher;

export const emitEvent = (channelName, eventName, data) => {
  const channel = pusher.subscribe(channelName);
  channel.emit(eventName, data);
};

export default pusher;
