import { UnleashFeature } from 'types/Unleash';

export type getFeatureFlagProps = {
  feature: string;
};

export type Response<T> = {
  isEnabled: boolean;
  variantValue?: T;
};

const getFeatureFlag = <T>({ feature }: getFeatureFlagProps): Response<T> => {
  const data = window.FeatureFlags;

  if (!data) {
    console.warn(
      `Feature flags couldn't be loaded, check if unleash still up`,
      feature
    );

    return {
      isEnabled: false,
    };
  }

  const foundFeature = data.find((ft: UnleashFeature) => ft.name === feature);

  if (!foundFeature) {
    console.warn(
      `Feature '${feature}' doesn't exist. Check the correct feature name at FF`
    );

    return {
      isEnabled: false,
    };
  }

  if (!foundFeature.variant.payload) {
    return {
      isEnabled: foundFeature.isEnabled,
    };
  }

  const { type, value } = foundFeature.variant.payload;

  return {
    isEnabled: foundFeature.isEnabled,
    variantValue: type !== 'json' ? value : JSON.parse(value),
  };
};

export { getFeatureFlag };
