import Cookies from 'js-cookie';
import { Socket as PhoenixSocket } from 'phoenix';
import { reconnectAfterMs } from 'utils/reconnectAfterMs';

const WEBSOCKET_URI = window.ENV?.PFY_PIPEFY_ACTIVITIES_WEBSOCKET_URI || '';

const createActivitiesSocket = (): PhoenixSocket => {
  const activitiesSocket = new PhoenixSocket(WEBSOCKET_URI, {
    params: () => ({ id_token: Cookies.get('id_token') }),
    reconnectAfterMs,
  });

  activitiesSocket.channel('room:lobby').join();
  return activitiesSocket;
};

export const activitiesSocket = createActivitiesSocket();
