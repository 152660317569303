import {
  GET_FEATURE_FLAGS,
  GetFeatureFlagSuccess,
} from 'Commons/graphql/queries/GetFeatureFlag';
import { useQuery, client } from 'utils/apolloAbstraction/apolloAbstraction';
import {
  getFeatureFlag,
  getFeatureFlagProps,
  Response,
} from 'utils/getFeatureFlag';

export type Params = getFeatureFlagProps & {
  skipQuery?: boolean;
};

export type UseFeatureFlagReturn<Data> = Response<Data> & {
  loading: boolean;
};

const useFeatureFlag = <Data = unknown>({
  feature,
  skipQuery = true,
}: Params): UseFeatureFlagReturn<Data> => {
  const { data, loading } = useQuery<GetFeatureFlagSuccess>(GET_FEATURE_FLAGS, {
    context: { internal: true },
    skip: skipQuery,
    client,
  });

  if (skipQuery) {
    return {
      ...getFeatureFlag<Data>({ feature }),
      loading: false,
    };
  }

  return {
    isEnabled:
      data?.featureFlags.find(({ name }) => name === feature)?.isEnabled ||
      false,
    loading,
  };
};

export { useFeatureFlag };
